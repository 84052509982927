export const queryNLP = (uid, query) =>
  fetch(
    'https://trackapi.nutritionix.com/v2/natural/nutrients',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-app-id': 'f0e2750b',
        'x-app-key': '3d839499bcd20afdf286dd48b3bcea55',
        'x-remote-user-id': uid,
      },
      body: JSON.stringify({ query, locale: 'en_US' }),
    },
  )
    .then(res => res.json())
    .catch(err => {
      console.error(err)
      return null
    })

export const queryInstant = (uid, query) =>
  fetch(
    `https://trackapi.nutritionix.com/v2/search/instant?query=${query}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-app-id': 'f0e2750b',
        'x-app-key': '3d839499bcd20afdf286dd48b3bcea55',
        'x-remote-user-id': uid,
      },
    },
  )
    .then(res => res.json())
    .catch(err => {
      console.error(err)
      return null
    })