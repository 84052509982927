import React from 'react'
import { connect } from 'react-redux'
import {
  Box, Text,
} from 'grommet'

import AppLayout from '../components/app-layout'
import LensHighlights from '../components/pages/lens/lens-highlights'
import LensNutrition from '../components/pages/lens/lens-nutrition'
import LensInformation from '../components/pages/lens/lens-information'
import LensInput from '../components/pages/lens/lens-input'

import { queryNLP } from '../util/nutritionix'
import { analyseFoods, getDefault, getFoodId } from '../util/analysis'
import { track } from '../util/analytics'
import { StatusWarning } from 'grommet-icons'

const mapStateToProps = state => ({
  uid: state.user.uid,
  getAnalysis: analyseFoods(state),
})

const mapDispatchToProps = dispatch => ({
})

const Lens = ({
  uid,
  getAnalysis,
}) => {
  const [query, setQuery] = React.useState(null)
  const [ingredients, setIngredients] = React.useState([])
  const [analysed, setAnalysed] = React.useState(null)

  const changeQty = (qty, idx, subtract) => {
    track(
      'Lens Quantity Changed',
      {
        foodId: getFoodId(ingredients[idx].data),
        foodName: ingredients[idx].data.food_name,
      },
    )
    const newQty = Math.max(0, subtract ? qty - 0.5 : qty + 0.5)
    const changed = ingredients.map((item, index) => ({
      ...item,
      servingQty: idx === index ? newQty : item.servingQty,
    }))
    setIngredients(changed)
  }

  const getFoodData = async (query) => {
    if (uid) {
      const result = await queryNLP(uid, query)
      if (result && result.foods && result.foods.length > 0) {
        for (const food of result.foods) {
          track(
            'Lens Food Received',
            { foodId: getFoodId(food), foodName: food.food_name },
          )
        }
        setIngredients(
          result.foods.map(food => ({ data: food, ...getDefault(food) })),
        )
      } else {
        track('Lens Food No Result', { query })
      }
    }
  }

  React.useEffect(() => {
    if (query && query.length > 1) {
      getFoodData(query)
    }
  }, [query])

  React.useEffect(() => {
    if (ingredients && ingredients.length > 0) {
      setAnalysed(getAnalysis(ingredients))
    }
  }, [ingredients])

  return (
    <AppLayout page="lens">
      <Box
        pad={{ horizontal: 'medium', vertical: 'medium' }}
        align="center"
      >
        <Box
          direction="row"
          align="start"
          justify="center"
          wrap
        >
          <Box width={{ max: 'large' }}>
            <LensInput
              uid={uid}
              onQuery={res => setQuery(res)}
            />
            {
              !uid && (
                <Box
                  background="status-warning"
                  round="medium"
                  pad="medium"
                  align="center"
                >
                  <StatusWarning />
                  <Text textAlign="center">
                    Please create an account to use Gini Lens
                  </Text>
                </Box>
              )
            } 
            {
              analysed && (
                <>
                  <LensInformation
                    score={analysed.analysis.score.total}
                    calories={analysed.servingCals}
                    weight={analysed.servingWeight}
                    ingredients={analysed.ingredients}
                    onAdd={(item, idx) => changeQty(item.servingSize, idx)}
                    onSubtract={(item, idx) =>
                      changeQty(item.servingSize, idx, true)
                    }
                  />
                  <Box direction="row" justify="center" wrap>
                    <LensHighlights
                      highlights={analysed.analysis.highlights}
                      score={analysed.analysis.score.total}
                    />
                  </Box>
                </>
              )
            }
          </Box>
          {
            analysed && (
              <LensNutrition
                macros={analysed.analysis.labels.macros}
                micros={analysed.analysis.labels.micros}
              />
            )
          }
        </Box>
      </Box>
    </AppLayout>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lens)
