import React from 'react'
import { Box, Text } from 'grommet'

import LensMicros from './lens-micros'
import LensMacros from './lens-macros'

import colours from '../../../util/colours'

const LensNutrition = ({
  micros,
  macros,
}) => (
  <Box
    flex="grow"
    basis="small"
    width={{ min: 'small', max: 'large' }}
    pad="medium"
    background={colours.WHITE}
    round="medium"
    elevation="xsmall"
    margin={{ bottom: "medium", horizontal: "small" }}
  >
    <Text
      size="large"
      weight="bold"
      margin={{ vertical: "medium" }}
    >
      Macronutrients
    </Text>
    <LensMacros macros={macros} />
    <Text
      size="large"
      weight="bold"
      margin={{ vertical: "medium" }}
    >
      Micronutrients
    </Text>
    <LensMicros micros={micros} />
  </Box>
)

export default LensNutrition
