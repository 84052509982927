import { analyseNixItem, analyseNixGroup } from 'gini.analysis'
import { colours } from './colours'

export const getDefault = food => ({
  servingQty: food.zero ? 0 : food.serving_qty,
  measureName: food.serving_unit,
})

export const analyseFood = state =>
  (food, servingQty, measureName) =>
    analyseNixItem(
      food,
      state.targets,
      state.nutrition,
      state.preferences,
      typeof servingQty === 'number' ? servingQty : getDefault(food).servingQty,
      measureName || getDefault(food).measureName,
    )

export const analyseFoods = state =>
  (ingredients, servingQty = 1, servingBase = 1) =>
    analyseNixGroup(
      ingredients,
      state.targets,
      state.nutrition,
      state.preferences,
      servingQty,
      servingBase,
    )

export const transformMicros = micros =>
  micros
    .filter(m => m.label)
    .sort((a, b) => {
      const diff = (b.percent || 0) - (a.percent || 0)
      if (diff !== 0) {
        return diff
      }
      if (typeof b.percent === 'number' && typeof a.percent !== 'number') {
        return 1
      }
      if (typeof b.percent !== 'number' && typeof a.percent === 'number') {
        return -1
      }
      return 0
    })

export const transformMacros = macros => {
  const children = macros
    .filter(m => m.parent)
    .map(m => m.id === 'fat_saturated' ? { ...m, percent: null } : m)
    .reduce((acc, m) => {
      const childList = acc[m.parent] ? [...acc[m.parent], m] : [m]
      return { ...acc, [m.parent]: childList }
    }, {})

  return macros
    .filter(m => !m.parent)
    .sort((a, b) => (b.percent || 0) - (a.percent || 0))
    .map(m => ({ ...m, children: children[m.id] || null }))
}

export const transformHighlights = (highlights, score) =>
  Object.values(highlights)
    .filter(hl => (
      hl.visible === true
      && (score > 0.5 ? hl.healthy === true : hl.healthy === false)
    ))

export const getScoreLabel = score => {
  if (typeof score !== 'number' || isNaN(score)) {
    return null
  }
  if (score >= 0.82) {
    return 'Great for you'
  }
  if (score >= 0.64) {
    return 'Good for you'
  }
  if (score >= 0.36) {
    return 'Okay for you'
  }
  return 'Bad for you'
}

export const getScoreColor = score => {
  if (typeof score !== 'number' || isNaN(score)) {
    return colours.GREY_1
  }
  if (score >= 0.64) {
    return colours.HIGHLIGHT_1
  }
  if (score >= 0.36) {
    return colours.PRIMARY_2
  }
  return colours.SECONDARY_1
}

export const getFoodId = food => (food.nix_item_id || food.ndb_no)

export const getFoodSize = num => {
  const separated = roundQuantity(num)
  if (separated[1] === null) {
    return separated[0]
  }
  if (separated[0] === 0) {
    return separated[1]
  }
  return `${separated[0]}${separated[1]}`
}

export const roundQuantity = num => {
  const whole = Math.floor(num)
  const fract = num % 1
  if (fract >= 0.88) {
    return [whole + 1, null]
  }
  if (fract < 0.88 && fract >= 0.63) {
    return [whole, '¾']
  }
  if (fract < 0.63 && fract >= 0.38) {
    return [whole, '½']
  }
  if (fract < 0.38 && fract >= 0.19) {
    return [whole, '¼']
  }
  if (fract < 0.19 && fract >= 0.08) {
    return [whole, '⅛']
  }
  return [whole, null]
}
