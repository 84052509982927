import React from 'react'
import { Box, Text, Heading } from 'grommet'

import { pretty, maxNumber } from '../../../util/numbers'
import { transformMacros } from '../../../util/analysis'
import colours from '../../../util/colours'

const Row = ({ item, child }) => (
  <Box
    direction="row"
    align="center"
    width={{ min: 'small' }}
    pad={{ vertical: "xxsmall" }}
    wrap
  >
    <Box
      flex={false}
      pad={!child ? undefined : { left: "small" }}
    >
      <Text color={child ? colours.MID_1 : colours.DARK}>
        {item.label}&nbsp;
      </Text>
    </Box>
    <Box flex="grow">
      <Text
        color={child ? colours.MID_1 : colours.DARK}
        weight={'bold'}
      >
        {pretty(item.val)}{item.unit}
      </Text>
    </Box>
    <Box flex={false} width="3rem">
      <Text
        textAlign="end"
        color={child ? colours.MID_1 : colours.DARK}
      >
        {
          typeof item.percent === 'number'
            ? `${maxNumber(pretty(item.percent) || 0, 999, '+')}%`
            : ' '
        }
      </Text>
    </Box>
  </Box>
)

const LensMacros = ({ macros = [] }) => (macros && macros.length > 0)
  ? transformMacros(macros).map(macro => (
      <Box key={macro.id}>
        <Row item={macro} />
        {
          (macro.children && macro.children.length > 0) && (
            macro.children.map(macroChild => (
              <Row key={macroChild.id} item={macroChild} child />
            ))
          )
        }
      </Box>
    ))
  : null

export default LensMacros
