import React from 'react'
import {
  Button,
  Box,
  TextArea,
} from 'grommet'
import { colours } from '../../../util/colours'
import { track } from '../../../util/analytics'

const LensFreeform = ({
  onSearch,
}) => {
  const [query, setQuery] = React.useState('')

  return (
    <Box
      pad="medium"
      background={colours.WHITE}
      round="medium"
      elevation="xsmall"
      margin={{ bottom: "medium", horizontal: "small" }}
      height="small"
      align="center"
      pad="small"
    >
      <TextArea
        type="search"
        value={query}
        placeholder={`Enter one or multiple foods. Feel free to try something like "tomato, a piece of lettuce, 1 beef patty, and a bun" to analyse all foods at the same time.`}
        onChange={event => setQuery(event.target.value)}
        resize={false}
        fill
        plain
      />
      <Button
        label="Analyse"
        color={colours.HIGHLIGHT_1}
        onClick={() => {
          track('Lens Freeform Analyse', { query })
          onSearch(query)
        }}
        primary
      />
    </Box>
  )
}

export default LensFreeform
