import React from 'react'
import { Box, Text, Heading } from 'grommet'

import Bar from '../../bar'

import { pretty, maxNumber } from '../../../util/numbers'
import { transformMicros } from '../../../util/analysis'
import colours from '../../../util/colours'

const getBarColors = (risk, ulPercent) => {
  if (ulPercent > 100) {
    return {
      text: colours.DANGER_L1,
      fill: colours.DANGER_L1,
      bg: colours.MID_5,
    }
  }
  if (typeof risk === 'number') {
    return {
      text: colours.DARK,
      fill: colours.PRIMARY_L2,
      bg: colours.MID_5,
    }
  }
  return {
    text: colours.DARK,
    fill: colours.MID_3,
    bg: colours.MID_5,
  }
}

const LensMicros = ({ micros = [] }) => (micros && micros.length > 0)
  ? transformMicros(micros).map(micro => (
      <Box
        key={micro.id}
        direction="row"
        align="center"
        pad={{ vertical: "xxsmall" }}
        wrap
      >
        <Box
          direction="row"
          align="center"
          flex="grow"
        >
          <Box
            flex="grow"
            width={{ min: 'xsmall' }}
            pad={{ right: 'small' }}
          >
            <Text>
              {micro.label}
            </Text>
          </Box>
          {
            typeof micro.val === 'number' && (
              <Box flex={false} width="xsmall">
                <Text color={colours.MID_1} textAlign="end">
                  {pretty(micro.val)}{micro.unit}
                </Text>
              </Box>
            )
          }
        </Box>
        <Box
          flex={false}
          direction="row"
          align="center"
          pad={{ left: "small" }}
        >
          <Bar
            value={micro.targetPercent}
            bg={getBarColors(micro.risk, micro.ulPercent).bg}
            fill={getBarColors(micro.risk, micro.ulPercent).fill}
            bgStyle={{ width: '3.5rem' }}
          />
          <Box flex={false} width="3rem">
            <Text
              textAlign="end"
              color={getBarColors(micro.risk, micro.ulPercent).text}
            >
              {
                typeof micro.percent === 'number'
                  ? `${maxNumber(pretty(micro.percent) || 0, 999, '+')}%`
                  : ' '
              }
            </Text>
          </Box>
        </Box>
      </Box>
    ))
  : null

export default LensMicros
