import React from 'react'
import { Box } from 'grommet'

import { getScoreColor } from '../util/analysis'

const ScoreDot = ({
  score,
  size = "1.5rem",
  ...props
}) => (
  <Box
    width={size}
    height={size}
    flex={false}
    round="full"
    background={getScoreColor(score)}
    {...props}
  />
)

export default ScoreDot
