import React from 'react'
import { Box, Text, Button } from 'grommet'
import { SubtractCircle, AddCircle } from 'grommet-icons'

import ScoreDot from '../../score-dot'
import { getFoodSize } from '../../../util/analysis'
import { colours } from '../../../util/colours'
import { pretty } from '../../../util/numbers'

const measureIsUnit = measure => measure
  && ['kg', 'g', 'mg'].includes(measure)

const measureIsName = (measure, name) => measure
  && measure.toLowerCase().match(name.trim().toLowerCase()) !== null

const hideMeasure = (measure, name) =>
  !measure
  || measureIsName(measure, name)
  || measureIsUnit(measure)

const LensFoodItem = ({
  name,
  calories,
  measure,
  qty,
  weight,
  score,
  onAdd,
  onSubtract,
  disableSubtract,
}) => (
  <Box direction="row" align="center" wrap>
    <Box flex="grow" direction="row" align="center" >
      <ScoreDot score={score} />
      <Box direction="row" align="center" wrap>
        <Box pad={{ left: "medium" }}>
          <Text weight="bold">
            {name}
          </Text>
        </Box>
        <Box direction="row" align="center">
          {
            !hideMeasure(measure, name) && (
              <Box pad={{ left: 'medium' }}>
                <Text>
                  {getFoodSize(qty)} {measure}
                </Text>
              </Box>
            )
          }
          {
            (!measureIsName(measure, name) && measureIsUnit(measure)) && (
              <Box pad={{ left: 'medium' }}>
                <Text color={colours.DARK_3}>
                  {getFoodSize(qty)}{measure} / {calories} Cal
                </Text>
              </Box>
            )
          }
          {
            (!measureIsName(measure, name) && !measureIsUnit(measure)) && (
              <Box pad={{ left: 'medium' }}>
                <Text color={colours.DARK_3}>
                  {pretty(weight)}g / {calories} Cal
                </Text>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
    <Box
      flex={false}
      pad={{ left: 'medium' }}
      direction="row"
      align="center"
    >
      <Button
        style={{ borderRadius: '100%' }}
        disabled={disableSubtract}
        icon={<SubtractCircle />}
        onClick={onSubtract}
      />
      <Button
        style={{ borderRadius: '100%' }}
        icon={<AddCircle />}
        onClick={onAdd}
      />
    </Box>
  </Box>
)

export default LensFoodItem
