import React from 'react'
import { Box, Text } from 'grommet'

import LensFoodItem from './lens-food-item'
import ScoreBar from '../../score-bar'

import { colours } from '../../../util/colours'
import { pretty } from '../../../util/numbers'
import { getFoodId } from '../../../util/analysis'

const LensInformation = ({
  score,
  calories,
  weight,
  ingredients,
  onRemove,
  onAdd,
  onSubtract,
}) => (ingredients && ingredients.length > 0)
  ? (
    <Box
      pad="medium"
      background={colours.WHITE}
      round="medium"
      elevation="xsmall"
      margin={{ bottom: "medium", horizontal: "small" }}
    >
      <ScoreBar score={score} />
      {
        ingredients.length > 1 && (
          <Box margin={{ bottom: 'small' }}>
            <Box direction="row" align="center">
              <Text>
                Total Calories&nbsp;&nbsp;
              </Text>
              <Text weight="bold">
                {pretty(calories)}
              </Text>
            </Box>
            <Box direction="row" align="center">
              <Text>
                Total Weight&nbsp;&nbsp;
              </Text>
              <Text weight="bold">
                {weight}g
              </Text>
            </Box>
          </Box>
        )
      }
      {
        ingredients.map((item, idx) => (
          <LensFoodItem
            key={getFoodId(item.food)}
            name={item.food.food_name}
            calories={item.calories}
            weight={item.measureWeight * item.servingSize}
            measure={item.measureName}
            score={item.analysis.score.total}
            qty={item.servingSize}
            disableSubtract={item.servingSize <= 0}
            onRemove={() => onRemove(item, idx)}
            onAdd={() => onAdd(item, idx)}
            onSubtract={() => onSubtract(item, idx)}
          />
        ))
      }
    </Box>
  ) : null

export default LensInformation
