import React from 'react'
import {
  Box,
  Text,
  TextInput,
  Form,
} from 'grommet'
import colours from '../../../util/colours'
import { queryInstant } from '../../../util/nutritionix'
import { track } from '../../../util/analytics'

const LensAutocomplete = ({
  uid,
  onSelected,
}) => {
  const [query, setQuery] = React.useState('')
  const [suggestions, setSuggestions] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const searchRef = React.useRef()

  const getSuggestions = async () => {
    const anon = (
      window
      && window.analytics
      && window.analytics.user
      && window.analytics.user().anonymousId()
    ) || 'unknown'
    const result = await queryInstant(uid ? uid : anon, query)
    if (result) {
      setSuggestions(result.common.slice(0, 8))
    }
  }

  React.useEffect(() => {
    if (query && query.length > 1) {
      getSuggestions()
    } else {
      setSuggestions([])
    }
  }, [query])

  return (
    <Box
      ref={searchRef}
      direction="row"
      align="center"
      elevation="xsmall"
      background={colours.WHITE}
      pad={{ horizontal: 'small' }}
      margin={{ bottom: "medium", horizontal: "small" }}
      round={!open ? "medium" : {
        corner: 'top',
        size: 'medium',
      }}
    >
      <Form
        style={{ flexGrow: 1 }}
        onSubmit={() => {
          if (suggestions && suggestions[0]) {
            track('Lens Instant Select', { option: suggestions[0].food_name })
            setQuery(suggestions[0].food_name)
            onSelected(suggestions[0].food_name)
          }
        }}
      >
        <TextInput
          type="search"
          value={query}
          placeholder="Start typing a food..."
          dropTarget={searchRef.current}
          onSelect={event => {
            track('Lens Instant Select', { option: event.suggestion.value })
            setQuery(event.suggestion.value)
            onSelected(event.suggestion.value)
          }}
          onChange={event => setQuery(event.target.value)}
          onSuggestionsOpen={() => setOpen(true)}
          onSuggestionsClose={() => setOpen(false)}
          suggestions={suggestions.map((suggestion) => ({
            label: (
              <Box pad="small">
                <Text>
                  {suggestion.food_name}
                </Text>
              </Box>
            ),
            value: suggestion.food_name,
          }))}
          plain
        />
      </Form>
    </Box>
  )
}

export default LensAutocomplete
