import React from 'react'
import {
  Button,
  Box,
} from 'grommet'
import {
  Search,
  Edit,
} from 'grommet-icons'

import LensAutocomplete from './lens-autocomplete'
import LensFreeform from './lens-freeform'

import { colours } from '../../../util/colours'
import { track } from '../../../util/analytics'

const LensInput = ({
  uid,
  onQuery,
}) => {
  const [toggle, setToggle] = React.useState(false)

  return (
    <Box>
      <Box pad="small" gap="small" direction="row" align="center">
        <Button
          icon={<Search />}
          onClick={() => {
            track('Lens Input Changed', { type: 'instant' })
            setToggle(true)
          }}
          focusIndicator={false}
          label="Instant"
          color={toggle ? colours.HIGHLIGHT_1 : colours.GREY_2}
          primary
        />
        <Button
          icon={<Edit />}
          onClick={() => {
            track('Lens Input Changed', { type: 'freeform' })
            setToggle(false)
          }}
          focusIndicator={false}
          label="Freeform"
          color={!toggle ? colours.HIGHLIGHT_1 : colours.GREY_2}
          primary
        />
      </Box>
      {
        toggle ? (
          <LensAutocomplete uid={uid} onSelected={onQuery} />
        ) : (
          <LensFreeform onSearch={onQuery} />
        )
      }
    </Box>
  )
}

export default LensInput
