export const round = (num, dec = 0) =>
  Math.round(num * (10 ** dec)) / (10 ** dec)

export const pretty = (num, upper = 0, lower = 1) => {
  if (num >= 0.95) {
    return round(num, upper)
  }
  if (num < 0.95 && num > 0) {
    return round(num, lower)
  }
  return 0
}

export const maxNumber = (val, max = 999, plus) =>
  plus && val >= max
    ? `${Math.min(max, val)}${plus}`
    : Math.min(max, val)