import React from 'react'
import { Box } from 'grommet'

import colours from '../util/colours'

const Bar = ({
  value = 0,
  height = '1rem',
  bg = colours.MID_5,
  fill = colours.PRIMARY,
  bgStyle = {},
  fillStyle = {},
  bgProps = {},
  fillProps = {},
}) => (
  <Box
    flex={false}
    style={{
      width: '100%',
      overflow: 'hidden',
      backgroundColor: bg,
      borderRadius: height,
      height,
      ...bgStyle,
    }}
    {...bgProps}
  >
    <Box
      flex={false}
      style={{
        height: '100%',
        width: `${Math.min(100, Math.floor(value))}%`,
        backgroundColor: fill,
        ...fillStyle,
      }}
      {...fillProps}
    />
  </Box>
)

export default Bar
