import React from 'react'
import { Box, Text, Heading } from 'grommet'

import { transformHighlights } from '../../../util/analysis'
import colours from '../../../util/colours'

const LensHighlights = ({
  highlights = {},
  score = 0,
}) => {
  const data = transformHighlights(highlights, score)
  return data && data.length > 0
    ? transformHighlights(highlights, score).map(highlight => (
        <Box
          key={highlight.id}
          pad="medium"
          background={colours.WHITE}
          round="medium"
          elevation="xsmall"
          margin={{ bottom: "medium", horizontal: "small" }}
          flex="grow"
          basis="small"
          width={{ max: 'large' }}
        >
          <Text
            size="large"
            weight="bold"
            margin={{ vertical: "medium" }}
          >
            {highlight.label}
          </Text>
          <Text color={colours.DARK}>
            {highlight.description}
          </Text>
          {
            (highlight.association && highlight.association.length > 0) && (
              <>
                <Text
                  color={colours.MID_2}
                  size="small"
                  margin={{ vertical: "small" }}
                >
                  RELATED REPORTS
                </Text>
                {
                  highlight.association.map(association => (
                    <Text key={association.id}>
                      {association.label}
                    </Text>
                  ))
                }
              </>
            )
          }
        </Box>
      ))
    : null
}

export default LensHighlights
