import React from 'react'
import { Box, Heading, Text } from 'grommet'


import { getScoreColor, getScoreLabel } from '../util/analysis'
import Bar from './bar'

const ScoreBar = ({
  score,
}) => (
  <Box margin={{ bottom: "medium" }}>
    <Box pad={{ left: "small" }}>
      <Heading level={3} margin={{ bottom: "small" }}>
        {getScoreLabel(score)}
      </Heading>
    </Box>
    <Bar
      value={score * 100}
      height={'1.5rem'}
      fill={getScoreColor(score)}
    />
  </Box>
)

export default ScoreBar
